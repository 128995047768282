.auditoria {
    background-color: black;
    color: white;
    min-height: 100vh;
    padding-top: 1px; /* Considerar la altura de la navbar */
  }
  
  .container {
    max-width: 100%;
  }
  
  .form-control, .form-select {
    background-color: #111111;
    color: white;
    border: none;
  }
  
  .form-control::placeholder, .form-select::placeholder {
    color: #888888; /* Color más claro para el placeholder */
  }
  
  .btn-primary {
    background-color: #1E1E1E;
    border: none;
  }
  
  .section {
    margin-bottom: 30px;
  }
  
  .calendar {
    background-color: #222222;
    padding: 20px;
    border-radius: 10px;
    color: white;
    text-align: center;
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker__input-container {
    width: 100%;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
    background-color: #111111;
    color: white;
    border: none;
  }
  
  .react-datepicker__month-year-dropdown-container,
  .react-datepicker__header,
  .react-datepicker__month {
    background-color: #222222;
    color: white;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #1E1E1E !important;
  }
  
  .react-datepicker__day--today {
    background-color: #333333 !important;
  }
  