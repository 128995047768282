.bitacora {
  background-color: black;
  color: white;
  min-height: 100vh;
  padding-top: 1px; /* Considerar la altura de la navbar */
}

.container {
  max-width: 100%;
}

.form-control, .form-select {
  background-color: #111111;
  color: white;
  border: none;
}

.form-control::placeholder {
  color: #888888; /* Color más claro para el placeholder */
}

.table-responsive {
  max-height: 400px; /* Ajusta esto según tus necesidades */
  overflow-y: auto;
  margin-top: 20px;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #222222;
}

.table thead th {
  background-color: #222222;
}

.table tbody td {
  background-color: #111111;
}

.table th, .table td {
  border: 1px solid #333333;
}

.table th:nth-child(1),
.table td:nth-child(1) {
  width: 10%;
}

.table th:nth-child(2),
.table td:nth-child(2) {
  width: 10%;
}

.table th:nth-child(3),
.table td:nth-child(3) {
  width: 80%;
}

.btn-primary {
  background-color: #1E1E1E;
  border: none;
}
