.monitoreo {
    background-color: black;
    color: white;
    min-height: 100vh;
    padding-top: 1px; /* Considerar la altura de la navbar */
  }
  
  .container {
    max-width: 100%;
  }
  
  .form-control, .form-select {
    background-color: #111111;
    color: white;
    border: none;
  }
  
  .form-control::placeholder, .form-select::placeholder {
    color: #888888; /* Color más claro para el placeholder */
  }
  
  .table-responsive {
    max-height: 300px; /* Ajusta esto según tus necesidades */
    overflow-y: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .table thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #222222;
  }
  
  .table thead th {
    background-color: #222222;
  }
  
  .table tbody td {
    background-color: #111111;
  }
  
  .table th, .table td {
    border: 1px solid #333333;
  }
  
  .table th:nth-child(1),
  .table td:nth-child(1) {
    width: 10%;
  }
  
  .table th:nth-child(2),
  .table td:nth-child(2) {
    width: 10%;
  }
  
  .table th:nth-child(3),
  .table td:nth-child(3) {
    width: 10%;
  }
  
  .table th:nth-child(4),
  .table td:nth-child(4) {
    width: 60%;
  }
  
  .table th:nth-child(5),
  .table td:nth-child(5) {
    width: 10%;
  }
  
  .btn-primary {
    background-color: #1E1E1E;
    border: none;
  }
  