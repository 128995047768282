body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
  }
  
  .login-background {
    background-color: black;
  }
  
  .login-box {
    background-color: #111111 !important;
    border-radius: 20px;
    width: 300px;
  }
  
  .login-logo {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  .login-input {
    background-color: #1E1E1E !important;
    border: none;
    color: #C0C0C0 !important;
  }
  
  .login-input::placeholder {
    color: #A0A0A0 !important;
  }
  
  .login-button {
    background-color: #1E1E1E !important;
    border: none;
    border-radius: 10px;
    color: white !important;
    cursor: pointer;
    font-size: 20px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
  