/* Navbar.css */
.navbar {
  padding: 0.5rem 1rem; /* Reducir el padding */
  height: 60px; /* Ajustar la altura */
}

.navbar-logo {
  width: 40px; /* Ajustar el tamaño del logo */
  height: 40px; /* Ajustar el tamaño del logo */
}

.nav-icon {
  font-size: 1.5rem;
  color: white;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.2s;
}

.nav-icon:hover {
  transform: scale(1.2);
}

.container-fluid {
  height: 100%; /* Asegurar que los elementos ocupen el 100% de la altura */
}

.navbar-nav {
  display: flex;
  align-items: center;
  height: 100%;
}
