.dashboard {
    background-color: black;
    min-height: 100vh;
    padding: 20px;
    padding-top: 10px; /* Considerar la altura de la navbar */
  }
  
  .dashboard-card {
    background-color: #111111;
    color: white;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    height: 100%;
  }
  
  .shadow-sm {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  
  .container {
    max-width: 100%;
  }

  .profile-photo {
    width: 150px; /* Ajusta el ancho deseado */
    height: 150px; /* Ajusta la altura deseada */
    object-fit: cover; /* Esto asegura que la imagen se ajuste al tamaño del contenedor sin distorsionarse */
    border-radius: 10%; /* Esto hará que la imagen sea redonda */
    /* border: 5px solid transparent; Añade un borde transparente */
    box-shadow: 0 0 5px rgb(255, 255, 255), 0 0 10px rgb(255, 255, 255), 0 0 10px rgb(255, 255, 255), 0 0 10px rgb(255, 255, 255), 0 0 20px rgb(255, 255, 255); /* Añade el efecto de neón */
  }
  
  
  